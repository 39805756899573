import React from "react"
import Layout from "../Layout"
import { Content } from "../components/rings/Content"
import { useStaticQuery, graphql } from "gatsby"

export default function PrivacyPolicy() {
  const PrivacyData = useStaticQuery(graphql`
    query privacy {
      sanityTerms(_id: { eq: "7f312ccd-1298-4907-810a-3944a9517f79" }) {
        _id
        titel
        _rawLongMessage
        isShown
      }
    }
  `)
  return (
    <Layout>
      {PrivacyData && PrivacyData.sanityTerms && (
        <Content content={PrivacyData.sanityTerms} />
      )}
    </Layout>
  )
}
